import React from 'react';
import logo from './images/logo.jpeg';
import indiaFlag from './images/india.png'; // Make sure the images exist in your project
import madeInIndia from './images/lion.png';
import './Header.css'; // Importing the custom CSS

function Header() {
  return (
    <div>
      {/* Top Section with Logo, India Flag, and Made in India Image */}
      <div className="header-top container-fluid bg-light py-3" >
        <div className="d-flex justify-content-between align-items-center header-images"  >
          <img src={logo} alt="Logo" className="header-image" />
          <img src={indiaFlag} alt="India Flag" className="header-image" />
          <img src={madeInIndia} alt="Made in India" className="header-image" />
        </div>
      </div>

      {/* Contact Information */}
      

      {/* Navbar */}
      <nav className="navbar navbar-expand-lg bg-white navbar-light sticky-top p-0 px-4 px-lg-5" >
        <a href="/" className="navbar-brand d-flex align-items-center" >
        
        </a>
        <button type="button" className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse" >
          <div className="navbar-nav ms-auto py-4 py-lg-0"  >
            <a href="/" className="nav-item nav-link">Home</a>
            <a href="about" className="nav-item nav-link">About</a>
            <a href="service" className="nav-item nav-link">Services</a>
           

            <div className="nav-item dropdown">
              <a href="product" className="nav-link dropdown-toggle" id="productDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Product
              </a>
              <ul className="dropdown-menu" aria-labelledby="productDropdown">
                <li><a href="/product" className="dropdown-item">Moulds</a></li>
                <li><a href="/blow" className="dropdown-item">Blow Moulds</a></li>
                <li><a href="/pvc" className="dropdown-item">PVC Fitting Moulds</a></li>
                <li><a href="/agri" className="dropdown-item">Agri Fitting Moulds</a></li>
                <li><a href="/electric" className="dropdown-item">Electrical Fitting Moulds</a></li>
              </ul>
            </div>



            <a href="vdo" className="nav-item nav-link">Video Gallery</a>
            <a href="tech" className="nav-item nav-link">Technical Info</a>
            <a href="contact" className="nav-item nav-link">Contact</a>
          </div> 
        </div>
      </nav>
    </div>
  );
}

export default Header;
