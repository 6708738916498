import React from 'react';
import './Agri.css';
import a1 from './images/Agri-1.webp';
import a2 from './images/Agri-2.webp';
import a3 from './images/Agri-3.webp';
import a4 from './images/AgriM-1.webp';
import a5 from './images/AgriM-2.webp';
import a6 from './images/AgriM-3.webp';

function Agri() {
  const images = [a1, a2, a3, a4, a5, a6];

  return (
    <div>
      
      <div className="container-fluid page-header py-5 mb-5">
        <div className="container py-5">
          <h1 className="display-3 text-white mb-3 animated slideInDown">Agri Moulds</h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a className="text-white" href="#">Home</a>
              </li>
              <li className="breadcrumb-item">
                <a className="text-white" href="#">Pages</a>
              </li>
              <li className="breadcrumb-item text-white active" aria-current="page">
                Agri Moulds
              </li>
            </ol>
          </nav>
        </div>
      </div>

     
      <div className="container">
        <div className="row">
          {images.map((image, index) => (
            <div className="col-md-4 col-sm-6 mb-4" key={index}>
              <div className="image-container">
                <img src={image} alt={`Agri mould ${index + 1}`} className="img-fluid animated-image" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Agri;
